export function components(components = {}) {
    const els = [...document.querySelectorAll('[data-component]')]
    const resolveComponent = (component) => {
        if (component instanceof Promise) {
            return component
        }

        if (typeof component === 'function') {
            return new Promise((resolve) => resolve({ default: component }))
        }

        if (component.hasOwnProperty('loader')) {
            return component.loader()
        }

        return new Promise((resolve, reject) => reject())
    }

    els.forEach((el) => {
        const name = el.dataset.component
        const component = components[name]

        if (component) {
            resolveComponent(component)
                .then(({ default: component }) => component({ el }))
                .catch(() => console.error(`Component "${name}" not valid`))
        } else {
            console.warn(`Component "${name}" not available`)
        }
    })
}

export function lazyComponent(loader) {
    return { loader }
}

export function translate(key) {
    return window.translations?.[key] ?? key
}
