const menuParents = document.querySelectorAll(
    '.navigation-panel .menu-item-has-children > a'
)
const nextButtonHtml =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M8.525 5.593a.625.625 0 0 1 .882-.068l7 6c.29.25.29.7 0 .95l-7 6a.625.625 0 0 1-.814-.95L15.04 12 8.593 6.475a.625.625 0 0 1-.127-.8l.06-.082Z"></path></svg>'
const prevButtonHtml =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M15.475 18.407a.625.625 0 0 1-.882.068l-7-6a.625.625 0 0 1 0-.95l7-6a.625.625 0 0 1 .814.95L8.96 12l6.446 5.525a.625.625 0 0 1 .127.8l-.06.082Z"></path></svg>'

menuParents.forEach((menuParent) => {
    const nextButton = document.createElement('span')
    nextButton.innerHTML = nextButtonHtml
    nextButton.style.pointerEvents = 'none'
    menuParent.appendChild(nextButton)

    menuParent.addEventListener('click', (e) => {
        e.preventDefault()
        const child = e.target.nextElementSibling
        child.style.display = 'block'
        window.scrollTo(0, 0)

        if (!child.classList.contains('menu-opened')) {
            const clonedElement = e.target.cloneNode(true)
            clonedElement.classList.add('cloned-element')
            child.prepend(clonedElement)

            const backButton = document.createElement('p')
            backButton.innerHTML = prevButtonHtml
            backButton.classList.add('back-button')

            backButton.addEventListener('click', () => {
                child.style.display = 'none'
            })

            child.prepend(backButton)

            child.classList.add('menu-opened')
        }
    })
})
