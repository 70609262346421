const hoverInElements = document.querySelectorAll(
    '#header-primary #menu-main li'
)
const hoverOutElement = document.getElementById('header-primary')
const menuSolutions = document.getElementById('megamenu-solutions')
const menuProducts = document.getElementById('megamenu-products')
const menuAbout = document.getElementById('megamenu-about')

hoverInElements.forEach(function (hoverInElement) {
    hoverInElement.addEventListener('mouseenter', function (event) {
        if (event.target.classList.contains('megamenu-hover-solutions')) {
            menuSolutions.classList.remove('hidden')
        } else {
            menuSolutions.classList.add('hidden')
        }

        if (event.target.classList.contains('megamenu-hover-products')) {
            menuProducts.classList.remove('hidden')
        } else {
            menuProducts.classList.add('hidden')
        }

        if (event.target.classList.contains('megamenu-hover-about')) {
            menuAbout.classList.remove('hidden')
        } else {
            menuAbout.classList.add('hidden')
        }
    })
})

hoverOutElement.addEventListener('mouseleave', function () {
    menuSolutions.classList.add('hidden')
    menuProducts.classList.add('hidden')
    menuAbout.classList.add('hidden')
})
