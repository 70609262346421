import { components, lazyComponent } from '@/lib/helpers.js'

//
// Fonts
//
import '@fontsource-variable/albert-sans'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    header: lazyComponent(() => import('@/components/header.js')),
    'embed-video': lazyComponent(() => import('@/components/embed/video.js')),
    'embla-slider': lazyComponent(() => import('@/components/slider.js')),
    'video-player': lazyComponent(() => import('@/components/video-player.js')),
    fancybox: lazyComponent(() => import('@/components/fancybox.js')),
})

//
// Megamenu
//
import './megamenu'
import './menu.js'
